import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import PostTableItem from "@/entity/system/master/PostTableItem";
import API from "@/api/system/master/post";

@Options({
    name: "app-system-master-post",
    components: {
        "app-draggable-listview": DraggableListview,
    },
})
export default class Post extends BaseTableMixins<PostTableItem> implements Partial<BaseTableInterface<PostTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new PostTableItem();
        this.editModalItem = new PostTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<PostTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new PostTableItem();
        this.editModalItem = new PostTableItem();
        this.editModalItem.postStatus = 1;
        this.editModalTitle = "添加岗位";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.postName)) return "岗位名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.postStatus)) return "岗位状态不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改岗位 (" + this.editModalItem.postName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.postId)) return "岗位ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除岗位",
            message: "岗位名称: " + this.editModalItem.postName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.postId)) return "岗位ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "岗位排序";
    }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "岗位状态",
            message: "岗位: " + this.editModalItem.postName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.postStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.postStatus = this.editModalItem.postStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.postId)) return "岗位ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }
}
